/**
 * Some variables the user can overwrite
 */

// Some common breakpoints for widths of devices. Refer: https://www.freecodecamp.org/news/css-media-queries-breakpoints-media-types-standard-resolutions-and-more/
$screen-tablet:  480px;
$screen-laptop:  768px;
$screen-desktop: 1024px;

:root {
  --spacing: 1em;

  --body-bg:          var(--oc-white);
  --body-fg:          var(--oc-gray-1);
  --body-color-light: var(--oc-gray-6);
  --body-color:       var(--oc-gray-8);
  --body-color-dark:  var(--oc-black);
  --accent-color:     var(--oc-orange-8);
  --message-color:    var(--oc-green-8);

  --body-font:        -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  --title-font:       'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  --code-font:        'Courier New', Courier, monospace;
  --line-height:      1.5;
  --body-font-size:   16px;
}

@media (prefers-color-scheme: dark) {
  :root {
    --body-bg:          var(--oc-gray-9);
    --body-fg:          var(--oc-gray-8);
    --body-color-light: var(--oc-gray-6);
    --body-color:       var(--oc-gray-3);
    --body-color-dark:  var(--oc-white);
    --accent-color:     var(--oc-blue-3);
    --message-color:    var(--oc-green-3);
  }
}

@media screen and (min-width: $screen-laptop) {
  :root {
    --body-font-size: 18px;
  }
}
