/**
 * Styles for the header
 */

.masthead {
  margin-top: var(--spacing);
  margin-bottom: calc(3 * var(--spacing));
  text-align: center;
}

.masthead-title {
  margin: 0;

  a {
    color: inherit;

    &:hover,
    &:focus {
      text-decoration: none;
      color: var(--body-color-dark);
    }
  }
}

.masthead-tagline {
  margin-top: 0;
  margin-bottom: 0;
  color: var(--body-color-light);
}

.nav {
  font-weight: bold;
  font-size: .8em;
  margin-top: calc(2 * var(--spacing));

  .active {
    color: var(--body-color-dark);
  }
}

.nav-item {
  margin-right: var(--spacing);
  color: var(--body-color-light);

  &:hover,
  &:focus {
    text-decoration: none;
    color: var(--body-color-dark);
  }
}

@media screen and (min-width: $screen-laptop) {
  .masthead {
    text-align: left;
  }
}
